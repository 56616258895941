import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, HeroImg, Footer } from '../components/main';
import { Container, Heading } from '../components/layout';
import InventorySmall from '../components/inventory/inventory-small';

import { ROOT_URL } from '../redux/root-url';
import MarketReportButton from '../components/static/mr-button';

import { fetchInventories, fetch, MR_BUTTON  } from '../redux';
import { translate } from '../components/main/language';

import heroImg from '~/src/static/images/inventory.jpg';

class InventoryMain extends Component {
  renderHelper() {
    if(!this.props.inventory) return;
    let inventory = map(this.props.inventory, item => {
      if (!item.home) return null;
      return (
        <InventorySmall
          className="col-lg-4 col-ms-6"
          key={item._id}
          {...item}
        />
      )
    })
    if(this.props.mrButton)
      inventory.splice(2, 0, <MarketReportButton key="mr-button" {...this.props.mrButton} />)
    return inventory;
  }

  render() {
    console.log(this.props.mrButton);
    return (
      <MainWrapper>
        <Head title="Inventory | AVIONMAR - OUR INVENTORY" />
        <Nav />

        <HeroImg imgUrl={heroImg} className={"subpage"}>
          <h1>Inventory</h1>
        </HeroImg>

        <Container
          section="more-padding"
          container="middle-big"
          className="row flex-align-stretch justify-center"
        >
          <Heading
            text="Our Inventory"
            className="primary"
            fill="#00A7E1"
          />
          {this.renderHelper()}
        </Container>
        <Footer />
      </MainWrapper>
    );
  }
}

const InventoryT = translate(
  (props, lng) => {
    const test = props.inventory.map(item => {
      for(const key in item) {
        if(key.search(lng) !== -1)
          item[key.replace(`_${lng}`, '')] = item[key];
      }
      return item;
    })
    return {...props, inventory: test, lng};
  }
)(InventoryMain);



class Inventory extends Component {
  componentDidMount() {
    if(!this.props.mrButton)
      this.props.fetch(MR_BUTTON ,ROOT_URL.region('mrButton'));

    this.props.fetchInventories({ home: true });
  }

  render() {
    return <InventoryT {...this.props} />;
  }
}

const mapStateToProps = ({inventory, mrButton}) => ({inventory, mrButton})

export default connect(
  mapStateToProps,
  { fetchInventories, fetch }
)(Inventory);
