import React from 'react';

import icoTime from '~/src/static/svg/details-time.svg';
import icoPax from '~/src/static/svg/details-pax.svg';
import icoLanding from '~/src/static/svg/details-landing.svg';

export const DetailPreview = (props) => (
  <div className="column detail-preview">
    <div className="row flex-align-center justify-space-start">
      <img className="hours" src={icoTime}  alt="" />
      <h6 className="text first">{props.flighthours} <sup>hrs</sup></h6>
    </div>
    <div className="row flex-align-center justify-space-start">
      <img className="pax" src={icoPax} alt="" />
      <h6 className="text second">{props.pax} <sup>pax</sup></h6>
    </div>
    <div className="row flex-align-center justify-space-start">
      <img className="landings" src={icoLanding} alt="" />
      <h6 className="text">{props.landings} <sup>landings</sup></h6>
    </div>
  </div>
)
