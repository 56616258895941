import React from 'react';
import { useCookiePopup } from './Context';
import map from 'lodash/map';

import CheckBox from '../../ui/atoms/Checkbox';
// import AwesomeIcon from 'ui/atoms/AwesomeIcon';

const data = {
  necessary: {
    label: 'Necessary',
    // desc: 'test',
  },
  analytics: {
    label: 'Tracking & Statistics',
    // desc: 'test',
  },
  marketing: {
    label: 'Marketing',
    // desc: 'test',
  },
};

export const CookiePopup = () => {
  const { setCookiePreferences, setCookiePopupOpen, noticed, ...cookieState } =
    useCookiePopup();

  const handleOnChange = ({ target: { name, checked } }) => {
    setCookiePreferences({
      [name]: checked,
    });
  };

  return (
    <div className="cookie-notice__container row justify-end flex-align-end p-x2 pr-x3">
      <div className="cookie-notice">
        <div className="form-group">
          <h3 className=" fw-bold">Manage Cookie Consent</h3>
        </div>
        <div className="form-group pb-x3 gray-300">
          <p>
            To provide the best experiences, we use technologies like cookies to
            store and/or access device information. Consenting to these technologies
            will allow us to process data such as browsing behavior or unique IDs on
            this site. Not consenting or withdrawing consent, may adversely affect
            certain features and functions.
          </p>
        </div>

        <div className="pb-x3 row flex-align-stretch " style={{ margin: '0 -8px' }}>
          {map(cookieState, (value, key) => (
            <div key={key} className="col-md-12">
              <div className="form-group checkbox-container row flex-align-center justify-space-between">
                <label>
                  <div className="h6 fw-bold">{data[key].label}</div>
                  {/* <p>{data[key].desc}</p> */}
                </label>
                <CheckBox
                  checked={key === 'necessary' ? true : value}
                  onChange={key === 'necessary' ? null : handleOnChange}
                  name={key}
                />
              </div>
            </div>
          ))}
        </div>

        <div
          className="row justify-space-between"
          style={{ alignItems: 'stretch' }}
        >
          <div className="col-md-4">
            <button
              className="mr-1 btn-cookie-accept-selection"
              onClick={() => {
                setCookiePreferences({ noticed: true });
                setCookiePopupOpen(false);
              }}
            >
              Accept selection
            </button>
          </div>
          <div className="col-md-4">
            <button
              className="mr-1 btn-cookie-accept-nothing"
              onClick={() => {
                setCookiePreferences({
                  necessary: true,
                  analytics: false,
                  marketing: false,
                  noticed: true,
                });
                setCookiePopupOpen(false);
              }}
            >
              Deny all
            </button>
          </div>
          <div className="col-md-4">
            <button
              className="btn-cookie-accept-all"
              onClick={() => {
                setCookiePreferences({
                  necessary: true,
                  analytics: true,
                  marketing: true,
                  noticed: true,
                });
                setCookiePopupOpen(false);
              }}
            >
              Accept all
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CookiePopupButton = () => {
  const { setCookiePopupOpen } = useCookiePopup();

  return (
    <button id="open-cookie_popup" onClick={() => setCookiePopupOpen(true)}>
      {/* <AwesomeIcon icon="cookie" /> */}
      Cookies
    </button>
  );
};
