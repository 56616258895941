import React from 'react';
import { TabArrow } from './tab-arrow';
import Icon from '../../static/icon';

export const InventoryTab = (props) => (
  <div className="inventory-tab row flex-align-center justify-center">
    <Icon icon={props.icon} className="icon" />
    {/* <img className="icon" src={props.icon} /> */}
    <TabArrow  />
  </div>
);
