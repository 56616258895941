import React from 'react';

export const TabArrow = (props) => (
  <svg className="tab-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.94 110.88">
    <defs>
      <filter id="blurMe">
        <feGaussianBlur in="SourceGraphic" stdDeviation="4" />
      </filter>
    </defs>
    <polygon
      points="87.41 110.88 107.94 72.54 87.79 42.35 59.48 0 0 110.88 87.41 110.88"
      filter="url(#blurMe)"
      fill={props.fill || 'rgba(0,0,0, 0.4)'}
    />
    <polygon
      points="87.41 110.88 107.94 72.54 87.79 42.35 59.48 0 0 110.88 87.41 110.88"
      fill="#007097"
    />
  </svg>
);
