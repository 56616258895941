import {MR_BUTTON} from '../actions';

export default (state = null, action) => {
  switch (action.type) {
    case MR_BUTTON:
      return action.data;
    default:
      return state;
  }
}
