import React, { Component } from 'react';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, Footer } from '../components/main';
import { Container, Heading } from '../components/layout';


class PrivacyPolicy extends Component {
  render() {
    return (
      <MainWrapper>
        <Head
          title="Privacy Policy | AVIONMAR - DATA PROTECTION"
          description="The protection of your personal data is very important to us. Therefore we process your data exclusively on the basis of the legal regulations (DSGVO, TKG 2003)"
        />
        <Nav />

        <Container
          className="row flex-align-start justify-left financing"
          section="more-padding"
        >
          <div className="col-12">
            <Heading className="primary">DATA PROTECTION</Heading>
            <div className="col-10">
              <div className="col-12">
                <h6>Decleration of Information (Privacy Policy)</h6>
                <p>
                  The protection of your personal data is very important to
                  us. Therefore we process your data exclusively on the
                  basis of the legal regulations (DSGVO, TKG 2003). In this
                  privacy policy we inform you about the most important
                  aspects of data processing within our website.
                </p>
              </div>

              <div className="col-12">
                <h6>Contact us</h6>
                <p>
                  If you contact us via the form on the website or by
                  e-mail, your data will be stored for six months to process
                  the request and in case of follow-up questions. We will
                  not share this information without your consent.
                </p>
              </div>

              <div className="col-12">
                <h6>Shop data storage</h6>
                <p>
                  If you contact us via the form on the website or by
                  e-mail, your data will be stored for six months to process
                  the request and in case of follow-up questions. We will
                  not share this information without your consent.
                  <br />
                  <br />
                  In addition, for the purpose of contract processing, the
                  following data is also stored with us: first name, last
                  name, email address. The data provided by you are required
                  to fulfill the contract or to carry out pre-contractual
                  measures. Without this data, we can not conclude the
                  contract with you. A transfer of data to third parties
                  does not take place, with the exception of the transfer of
                  the credit card data to the processing bank / payment
                  service provider for the purpose of debiting the purchase
                  price, to the transport company / shipping company
                  commissioned by us to deliver the goods and to our tax
                  advisor to fulfill our tax obligations.
                  <br />
                  <br />
                  After termination of the shopping process, the data stored
                  with us will be deleted. In the case of a contract, all
                  data from the contractual relationship are stored until
                  the expiry of the tax retention period (7 years). The data
                  name, address, purchased goods and date of purchase are
                  also stored until the end of product liability (10 years).
                  Data processing takes place on the basis of the statutory
                  provisions of § 96 (3) TKG and art 6 para. 1 lit a
                  (consent) and / or lit b (necessary for fulfillment of the
                  contract) of the DSGVO.
                </p>
              </div>

              <div className="col-12">
                <h6>Cookies</h6>
                <p>
                  Our website uses so-called cookies. These are small text
                  files that are stored on your device using the browser.
                  They do no harm. We use cookies to make our offer
                  user-friendly. Some cookies remain stored on your device
                  until you delete them. They allow us to recognize your
                  browser on your next visit. If you do not want this, you
                  can set up your browser so that it informs you about the
                  setting of cookies and you allow this only in individual
                  cases.
                  <br />
                  <br />
                  Disabling cookies may limit the functionality of our
                  website. The following links provide instructions on how
                  to manage the cookie settings in the most popular
                  browsers:
                  <br />
                  <br />
                </p>
                <ul className="list">
                  <li>
                    <a
                      href="http://windows.microsoft.com/de-at/windows-vista/Block-or-allow-cookies"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="primary underline link"
                    >
                      {" "}
                      Internet Explorer™
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://apple-safari.giga.de/tipps/cookies-in-safari-aktivieren-blockieren-loeschen-so-geht-s/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="primary underline link"
                    >
                      {" "}
                      Safari™
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="primary underline link"
                    >
                      {" "}
                      Chrome™
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="primary underline link"
                    >
                      {" "}
                      Firefox™
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://help.opera.com/Linux/9.01/de/cookies.htmlWeb-Analyse/ Social Plugins"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="primary underline link"
                    >
                      {" "}
                      Opera™
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12">
                <h6>Facebook Connect & Facebook Impressions</h6>
                <p>
                  Our website uses so-called social plugins (“plugins”) from
                  the social network Facebook. This service is offered by
                  the company Facebook Inc. (“Provider”). Facebook is
                  operated by Facebook Inc., 1601 S. California Ave, Palo
                  Alto, CA 94304, USA (“Facebook”). An overview of the
                  plugins of Facebook and their appearance can be found
                  here: https://developers.facebook.com/docs/plugins
                  <br />
                  <br />
                  When you visit a page of our website that contains such a
                  plugin, your browser connects directly to the Facebook
                  servers. The content of the plugin is transmitted by the
                  respective provider directly to your browser and
                  integrated into the page. By integrating the plugins, the
                  providers receive the information that your browser has
                  accessed the corresponding page of our website, even if
                  you do not have a profile or are currently not logged in.
                  This information (including your IP address) is
                  transmitted by your browser directly to a server of the
                  respective provider in the USA and stored there. If you
                  are logged in to one of the services, the providers can
                  directly allocate the visit to our website to your profile
                  on Facebook. If you interact with the plugins, for example
                  by clicking the “Like” button, the corresponding
                  information is also transmitted directly to a server of
                  the provider and stored there. The information will also
                  be posted on the social network, on your account and
                  displayed there to your contacts. Purpose and scope of the
                  data collection and the further processing and use of the
                  data by the provider as well as your related rights and
                  settings options to protect your privacy, please refer to
                  the privacy policy of the provider.
                </p>
                <br />
                <p>
                  Privacy policy of Facebook:{" "}
                  <a
                    className="link"
                    href="http://www.facebook.com/policy.php"
                  >
                    http://www.facebook.com/policy.php
                  </a>
                  <br />
                  Facebook as data controller or processor:{" "}
                  <a
                    className="link"
                    href="https://www.facebook.com/business/gdpr#Facebook-als-Datenverantwortlicher-vs.-Auftragsverarbeiter"
                  >
                    https://www.facebook.com/business/gdpr#Facebook-als-Datenverantwortlicher-vs.-Auftragsverarbeiter
                  </a>
                  <br />
                  Privacy Shield Facebook:{" "}
                  <a
                    className="link"
                    href="https://www.facebook.com/about/privacyshield#"
                  >
                    https://www.facebook.com/about/privacyshield#
                  </a>
                  <br />
                  <br />
                  <br />
                  Your IP address will be recorded but immediately
                  pseudonymised [eg by deleting the last 8 bits]. As a
                  result, only a rough localization is possible.
                  <br />
                  <br />
                  The relationship with the web analytics provider is based
                  on standard contractual clauses / European Commission
                  Appropriateness Decisions in the US case: “Privacy
                  Shield”.
                  <br />
                  <br />
                  The data processing takes place on the basis of the legal
                  regulations of the § 96 para 3 TKG as well as the art 6
                  para 1 a) (consent) and / or f (legitimate interest) of
                  the DSGVO.
                  <br />
                  <br />
                  Our concern in the sense of the DSGVO (legitimate
                  interest) is the improvement of our offer and our website.
                  Since the privacy of our users is important to us, the
                  user data is pseudonymized.
                </p>
              </div>

              <div className="col-12">
                <h6>Your rights</h6>
                <p>
                  In principle, you have the rights to information,
                  correction, deletion, restriction, data portability,
                  revocation and opposition. If you believe that the
                  processing of your data violates data protection law or if
                  your data protection claims have otherwise been violated
                  in a way, you can complain to the supervisory authority.
                  In Austria, this is the data protection authority.
                </p>
              </div>

              <div className="col-12">
                <h6>
                  You can reach us under the following contact details:
                </h6>
                <p>
                  <b>AVIONMAR GmbH</b> <br />
                  <b>Phone:</b>{" "}
                  <a className="link" href="tel:+43 4272 44 7 66">
                    +43 4272 44 7 66
                  </a>
                  <br />
                  <b>Email:</b>{" "}
                  <a className="link" href="mailto:office@avionmar.com">
                    office@avionmar.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Container>
        <Footer />
      </MainWrapper>
    );
  }
}
export default PrivacyPolicy;
