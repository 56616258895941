import React from 'react';
import styles from './logo-img.module.css';

export const Logo = (props) => (
  <div className="logoInLeiste row flex-align-baseline justify-center">
    <div
      className={styles.img}
      style={{
        backgroundImage: `url(${props.logo || ''})`,
      }}
    />
  </div>
);
