// http://avion.skymedia.at/api
const generateRootUrl = function() {
  this.rootUrl = 'https://avionmar.com/cockpit/api';
  this.token = '?token=fdb207c458c6d924fd42ff673c201a';

  this.collection = function(url) {
    return `${this.rootUrl}/collections/get/${url}${this.token}`;
  };
  this.region = function(url) {
    return `${this.rootUrl}/regions/data/${url}${this.token}`;
  };
};
export const SERVER_URL = 'https://avionmar.com';
export const API_URL = 'https://avionmar.com';
export const ROOT_URL = new generateRootUrl();
