import React, { Component } from 'react';
import axios from 'axios';
import { ROOT_URL, API_URL } from '../../redux/root-url';
import Slider from 'react-slick';
import { Logo } from './logo';
import './logoleiste.min.css';

export default class Logoleiste extends Component {
  state = {
    logos: [],
  };

  componentDidMount() {
    axios.post(ROOT_URL.region('logoslider')).then((res) => {
      this.setState({
        logos: res.data.logoslider,
      });
    });
  }

  renderHelper = () => {
    if (this.state.logos.length < 1) return;
    const logos = this.state.logos.map((item, i) => {
      return <Logo key={i} logo={`${API_URL}${item.path}`} />;
    });
    return logos;
  };

  render() {
    const settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 5,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 820,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
      arrows: false,
    };

    return (
      <div>
        <Slider className="logoleiste" {...settings}>
          {this.renderHelper()}
        </Slider>
      </div>
    );
  }
}
