import React from 'react';
export const HeroImg = (props) => (
  <div
    className={` hero-img
      ${props.className || ''}
    `}
    style={{
      backgroundImage: `url(${props.imgUrl || ''})`,
      backgroundPosition: props.backgroundPosition
        ? props.backgroundPosition
        : 'center',
      ...props.style,
    }}
  >
    <div
      className={`
      hero-text row flex-align-center
      ${props.className ? 'justify-left' : 'justify-center'}
    `}
    >
      <svg
        className="hero-balken"
        preserveAspectRatio="xMinYMin meet"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 89.38 128.93"
      >
        <path
          d="M20.8,0L0,39.1l14.2,19.7c0-0.4,0.1-1,0.2-1.8c0-0.3,0.1-0.6,0.1-1c0-0.2,0.1-0.4,0.1-0.5
          c0.1-0.4,0.1-0.7,0.2-1.1c0-0.2,0.1-0.4,0.1-0.6c0.1-0.4,0.2-0.8,0.3-1.3c0.3-1.1,0.6-2.2,1.1-3.4c0.3-0.7,0.6-1.4,0.9-2
          c0.8-1.6,1.7-3.1,2.9-4.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0h0.1c0.2,0,0.5,0.1,0.6,0.3
          c5.4,5.8,5.9,16.4,5.9,16.5v17.1c0,0.2,0.1,0.3,0.2,0.4l12,9.2c0.3,0.1,25.6,13.8,28,15.1l3.6,7.3l0.1,0.8l0,0v0l-2.2-1.7
          c0,0-0.1-0.1-0.2-0.1l-25.5-8.1l21.3,29.6h24.8L20.8,0z"
        />
      </svg>
      <div>{props.children}</div>
    </div>
  </div>
);
