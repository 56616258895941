import React, { Component } from 'react';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, HeroImg, Footer } from '../components/main';
import { Container } from '../components/layout';
import heroImg from '~/src/static/images/sales1.jpg';
import sales2 from '~/src/static/images/sales2.jpg';

class Aquisition extends Component {
  render() {
    return (
      <MainWrapper>
        <Head
          title="Sales | AVIONMAR - SELLING YOUR AIRCRAFT"
          description="AVIONMAR can provide you up to date market updates on any aircraft on the market."
        />
        <Nav />

        <HeroImg imgUrl={heroImg} className={"subpage"}>
          <h1>Sales</h1>
        </HeroImg>

        <Container className="financing row flex-align-start justify-left">
          <div className="col-12">
            <div className="col-12">
              <h4 className="fhead">
                SELLING <br />
                <span className="span2 thin uppercase">YOUR AIRCRAFT</span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="col-12">
              <p>
                Owners often peruse the various types and models for their
                next aircraft to buy, or assess when they may decide to sell
                their current one. AVIONMAR can provide you up to date
                market updates on any aircraft on the market. The
                information can include hours, price, days on market, and
                other general specifications. We also have a huge broker
                network where many “off-market” aircraft are discreetly
                available - often at attractive pricing.
              </p>
            </div>
            <div className="col-12">
              <div className="plus-block">
                <h6 className="plus">Sales</h6>
                <p>
                  When assisting with the purchase of an aircraft, AVIONMAR
                  offers a detailed market overview; examining the
                  appropriate models and negotiating on behalf of our
                  client. In addition, AVIONMAR takes care of all necessary
                  contracts for the purchase or sale and technically
                  observes any aircraft inspections – resulting in a one
                  stop shop solution for aircraft owners. Due to our
                  experience in financing and selling business jets we also
                  provide our expertise to banks and operators in single
                  transactions or in restructuring of aircraft portfolios.
                </p>
              </div>
            </div>
            <div className="col-12">
              <h6>Sales & Acquisition process</h6>
              <p>
                Our complete process for selling or acquiring your aircraft
                is structured to leave you in control and delegate the daily
                details to AVIONMAR. While there are many items and actions
                within the steps below, these are the things we do to
                support you - ensuring you the control you deserve, but
                taking away the workload from you to the maximum possible
                extent.
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="col-12">
              <img src={sales2} alt="" />
              <h5 className="fhead2 thin uppercase">
                When selling your <br />
                <span className="span2 lowercase bold">
                  aircraft, we will:
                </span>
              </h5>
            </div>
            <div className="col-12">
              <ul className="list">
                <li>
                  Provide current market research to set your asking price
                </li>
                <li>
                  Advertise with email, internet, brokerage platforms and
                  broadcast and other media
                </li>
                <li>Ensure quality photo coverage of the aircraft</li>
                <li>Cover all advertising costs</li>
                <li>Filter and qualify all the phone and email traffic</li>
                <li>Ensure offers include appropriate terms</li>
                <li>
                  Assist in contract negotiations (and escrow process)
                </li>
                <li>Manage the escrow process</li>
                <li>Manage and guide the pre-buy inspection process</li>
                <li>Manage documentation for sale completion</li>
                <li>
                  Manage the de-registration with the Civil Aviation
                  Authority{" "}
                </li>
                <li>Manage the closing process</li>
                <li>
                  Ensure privacy and discretion throughout the transaction
                </li>
              </ul>
            </div>
          </div>
        </Container>

        <Footer />
      </MainWrapper>
    );
  }
}
export default Aquisition;
