import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export class Modal extends Component {
  state = {
    setup:  null,
  }

  componentDidMount() {
    this.el = document.createElement('div');
    this.el.classList.add('modal');
    this.modalRoot = document.body;
    this.modalRoot.appendChild(this.el);

    this.setState({setup: true});
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }

  render() {
    if(!this.el || !this.state.setup) return null;
    return ReactDOM.createPortal(
      <ModalContainer
        closeModal={this.props.closeModal || null}
        open={this.props.open}
      >{this.props.children}
      </ModalContainer>,
      this.el,
    );
  }
}

const ModalContainer = ({ children, open, closeModal=null }) => {
  return (
    <div
      className={`${open ? 'open' : ''} modal-container row justify-center flex-align-center`}
      onClick={closeModal('modal-container')}
    >
      <div
        className={`modal-content card`}>
        {children}
      </div>
    </div>
  )
}
