import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import ReduxThunk  from 'redux-thunk'

import { rootReducer as reducer } from './reducers';

export const initStore = (initialState = {}) => {
  return createStore(
    reducer,
    composeWithDevTools(applyMiddleware(ReduxThunk))
  );
}
