import React from 'react';
import { Link } from 'react-router-dom';

const MarketReportButton = ({text}) => (
  <div className="col-lg-4 col-6 inventory-small no-anim">
    <Link to='/market-reports'>
      <button className="mr-button row flex-align-center justify-start">
        <span>{text}</span>
      </button>
    </Link>
  </div>
)
export default MarketReportButton;
