import React from 'react';

const Icon = ({icon, className}) => {
  switch (icon) {
    case 'details':
      return (
        <svg className={className || ''} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 291">\
          <path d="M248.84,268.83l-39-39a67.9,67.9,0,0,0-30.44-105V63.53a8.85,8.85,0,0,0-2.6-6.27L133.43,13.92a8.86,8.86,0,0,0-6.27-2.6H16.58a8.86,8.86,0,0,0-8.87,8.85V208.85a8.86,8.86,0,0,0,8.85,8.87H94.73a67.83,67.83,0,0,0,102.49,24.62l39,39a8.87,8.87,0,0,0,12.54-12.54ZM136,41.59,149.1,54.71H136ZM25.44,200V29.05h92.85V63.53a8.86,8.86,0,0,0,8.85,8.87h34.5v48.54q-2.8-.23-5.65-.23a68.45,68.45,0,0,0-14.09,1.46H40.79a5.91,5.91,0,1,0,0,11.82h74.86A68.39,68.39,0,0,0,99.8,150.54h-59a5.91,5.91,0,0,0,0,11.82H93.43A68.18,68.18,0,0,0,89.18,200Zm82.17-24.33h37.92a5.91,5.91,0,0,0,0-11.82h-33.1a50.12,50.12,0,0,1,63.7-21.19v72.6H113.64a50.25,50.25,0,0,1-6-39.6ZM124,227.07h58a5.91,5.91,0,0,0,5.91-5.91V149.94a42.67,42.67,0,0,1,3.47,3.16,50.09,50.09,0,0,1-67.4,74Z" fill="#09a6e0"/>
          <path d="M40.79,105.61h105.5a5.91,5.91,0,0,0,0-11.82H40.79a5.91,5.91,0,0,0,0,11.82Z" fill="#09a6e0" />
        </svg>
      );
    case 'contact':
      return (
        <svg className={className || ''}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 291">
          <path d="M225.5,233.75A82,82,0,0,1,204.61,245a70.83,70.83,0,0,1-24.48,4.15c-18.59,0-33-5.26-43-15.64-8.51-8.82-12.65-19.45-12.65-32.48,0-14.88,4.5-27.37,13.39-37.14,10.4-11.48,25.77-17.3,45.7-17.3,14.28,0,25.73,3.44,34,10.22s12.18,15.73,12.18,27.59c0,9.81-2.29,17.79-6.81,23.73-4.36,5.73-9,8.51-14.21,8.51a5,5,0,0,1-3.1-1c-.22-.16-.88-.65-.88-2.64a17.68,17.68,0,0,1,.5-3.8c.37-1.63,1-3.85,1.76-6.57l12.43-40.93H200L198,168.19a23,23,0,0,0-3.16-3.42c-4.33-3.84-9.82-5.79-16.33-5.79-9.34,0-18.08,4.66-26,13.86a47.07,47.07,0,0,0-11.69,31.46c0,8.58,2.42,15.76,7.21,21.32a24.27,24.27,0,0,0,19.24,8.62A30.67,30.67,0,0,0,183,229.81a26.44,26.44,0,0,0,6.68-5.67,17,17,0,0,0,3.53,5.12c3.27,3.31,8.19,5,14.62,5,10.4,0,19.84-4.93,28.07-14.67,8.08-9.56,12.17-21.21,12.17-34.64,0-16-5.74-29.2-17.06-39.28a60.44,60.44,0,0,0-24.45-12.82V77.54a8.85,8.85,0,0,0-2.6-6.27L160.57,27.93a8.86,8.86,0,0,0-6.27-2.6H43.72a8.86,8.86,0,0,0-8.87,8.85V222.86a8.86,8.86,0,0,0,8.85,8.87H113.9a67.61,67.61,0,0,0,7.89,11.19h0c13.16,15.2,32.06,22.9,56.18,22.9a93.11,93.11,0,0,0,34-6.06c10.26-4,18.14-8.42,23.41-13.11l2.38-2.12-9.46-12.9Zm-54.78-16a7.69,7.69,0,0,1-6.28-3c-1.83-2.2-2.76-5.62-2.76-10.19,0-6.67,2.16-13.54,6.41-20.39,4-6.41,8.37-9.53,13.42-9.53a8.23,8.23,0,0,1,6.82,2.86,10.57,10.57,0,0,1,2.63,7c0,6.48-2,13.93-5.93,22.14C181.46,214.13,176.78,217.76,170.72,217.76ZM163.17,55.6l13.07,13.12H163.17ZM52.58,214V43.06h92.85V77.54a8.86,8.86,0,0,0,8.85,8.87h34.5V130c-2.17-.13-4.38-.21-6.65-.21-12.63,0-24,2.15-34,6.37H67.93a5.91,5.91,0,1,0,0,11.82h61.43c-1.44,1.27-2.85,2.59-4.2,4a70.16,70.16,0,0,0-9.67,12.56H67.94a5.91,5.91,0,0,0,0,11.82h42a71.52,71.52,0,0,0-4,24.11A66.35,66.35,0,0,0,107.26,214Z" fill="#09a6e0"/>
          <path d="M67.93,119.62h105.5a5.91,5.91,0,0,0,0-11.82H67.93a5.91,5.91,0,1,0,0,11.82Z" fill="#09a6e0"/>
        </svg>
      );
    case 'gallery':
      return (
        <svg className={className || ''} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 281.39 216">
          <g>
            <path d="M266.16,9.29H15.26a4.4,4.4,0,0,0-4.49,4.3V202.41a4.4,4.4,0,0,0,4.49,4.3h250.9a4.38,4.38,0,0,0,4.46-4.29h0V13.59a4.38,4.38,0,0,0-4.45-4.3Zm-4.49,188.82H19.74V17.89H261.67Z" fill="#09a6e0"/>
            <path d="M266.17,211.21H15.26a8.91,8.91,0,0,1-9-8.8V13.59a8.91,8.91,0,0,1,9-8.8H266.17a9,9,0,0,1,6.28,2.53,8.62,8.62,0,0,1,2.67,6.29V202.41a8.63,8.63,0,0,1-2.68,6.28A9,9,0,0,1,266.17,211.21ZM24.24,193.61H257.17V22.39H24.24Z" fill="#1ca6df"/>
          </g>
          <g>
            <path d="M82.46,104.14a25,25,0,1,0-25-25,24.95,24.95,0,0,0,25,25Zm0-41a16,16,0,1,1-16,16A16,16,0,0,1,82.46,63.14Z" fill="#09a6e0"/>
            <path d="M82.46,105.64h0a26.45,26.45,0,0,1-.1-52.9h.05a26.45,26.45,0,0,1,.05,52.9Zm-.05-49.9h0a23.45,23.45,0,1,0,23.5,23.4A23.5,23.5,0,0,0,82.36,55.74Zm0,40.9A17.5,17.5,0,1,1,100,79.14,17.52,17.52,0,0,1,82.46,96.64Zm0-32A14.5,14.5,0,1,0,97,79.14,14.52,14.52,0,0,0,82.46,64.64Z" fill="#1ca6df"/>
          </g>
          <g>
            <path d="M42.15,184.16a4.47,4.47,0,0,0,3-1.11l73.09-64.34,46.1,46.14a4.47,4.47,0,0,0,6.32-6.32L149.13,137l41.13-45,50.45,46.25a4.48,4.48,0,1,0,6.06-6.61h0L193,82.29a4.64,4.64,0,0,0-3.21-1.16,4.42,4.42,0,0,0-3.11,1.44L142.8,130.63l-21.26-21.24a4.47,4.47,0,0,0-6.12-.2L39.18,176.31a4.49,4.49,0,0,0-.39,6.34h0a4.44,4.44,0,0,0,3.37,1.51Z" fill="#09a6e0"/>
            <path d="M42.2,185.66h-.08a5.94,5.94,0,0,1-4.46-2,6,6,0,0,1,.52-8.46l76.24-67.12a6,6,0,0,1,8.17.27l20.15,20.13,42.82-46.9a6,6,0,0,1,4.16-1.93A6,6,0,0,1,194,81.17l53.79,49.35a6,6,0,1,1-8.09,8.82L190.36,94.1l-39.15,42.83,20.52,20.54a6,6,0,0,1-8.42,8.46l-45.13-45.17-72,63.41A6,6,0,0,1,42.2,185.66Zm-.05-3h0v0a3.08,3.08,0,0,0,2-.74l74.15-65.27,47.09,47.14a2.91,2.91,0,0,0,2.1.8,3,3,0,0,0,2.08-5L147.06,137l43.11-47.17,51.56,47.27a3,3,0,0,0,4-4.4L192,83.4a3.12,3.12,0,0,0-2.15-.77,2.93,2.93,0,0,0-2,1L142.85,132.8l-22.37-22.35a3,3,0,0,0-4.07-.13L40.17,177.44a3,3,0,0,0-.26,4.22,2.85,2.85,0,0,0,2.24,1v0Z" fill="#1ca6df"/>
          </g>
        </svg>
      );
    case 'phone':
      return (
        <svg className={className || ''} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 291">
          <path fill="#09a6e0" d="M172.88,291a45.67,45.67,0,0,1-10.25-1.2c-15.38-3.62-25-14.45-28.19-18C118,253.34,95.3,219.49,73.83,181.25S35.36,106.11,28.14,82.3c-1.39-4.52-5.63-18.37-.69-33.39C32.29,34.27,42.06,27.42,49,23.55L87.34,2a15.77,15.77,0,0,1,21.49,6l32.27,57.5A15.79,15.79,0,0,1,135,87.05l-15.78,8.85a12.42,12.42,0,0,0,.66,3.88c3.59,11.51,16.63,34.76,21.54,43.52s18,32.06,25.91,41a12.38,12.38,0,0,0,3,2.58l15.82-8.87a15.78,15.78,0,0,1,21.48,6.12l32.23,57.42a15.81,15.81,0,0,1-6,21.48l-38.41,21.53A44.7,44.7,0,0,1,172.88,291ZM93.39,21.77,58.84,41.15c-4.83,2.7-9.65,6.28-12.23,14.08-2.63,8-.79,15.93.82,21.19,6.83,22.53,23.27,58,44,95s42.51,69.56,58.08,87c3.69,4.14,9.52,9.85,17.7,11.78,8,1.84,13.54-.41,18.31-3.11l34.6-19.39-28-49.82-13.66,7.66c-6.47,3.64-17.12,2.64-26.3-7.83-8.17-9.3-18.91-27.69-28.33-44.46s-19.49-35.47-23.21-47.39c-4.13-13.27.56-22.89,7-26.53h0l13.65-7.66ZM190,194l0,0Zm-21.37-6.12h0Zm-51-91h0Zm7.61-27.4h0Zm-28-49.82h0Zm-6-1.7h0Z"/>
        </svg>
      )
    case 'marker':
      return (
        <svg className={className || ''} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 291">
          <path fill="#09a6e0" d="M132.64,291c-4.91,0-7.21-2.86-11.39-8.05-2-2.5-5.34-6.77-9.81-13a502.41,502.41,0,0,1-28.73-44.75c-19.06-33.47-41.78-83-41.78-129,0-26.09,7.06-48.47,20.43-64.72C77.55,11.92,101.53,2,132.64,2s55.12,9.92,71.29,29.48c13.41,16.29,20.48,38.67,20.48,64.74,0,46-22.74,95.54-41.81,129A502.8,502.8,0,0,1,153.85,270c-4.48,6.2-7.81,10.47-9.82,13C139.85,288.14,137.55,291,132.64,291Zm0-269.94c-25.16,0-44.19,7.59-56.57,22.57C65.56,56.41,60,74.6,60,96.22c0,40.56,20.2,85.35,37.14,115.79a478.18,478.18,0,0,0,35.51,54.57A478.18,478.18,0,0,0,168.18,212c17-30.43,37.18-75.22,37.18-115.79,0-21.6-5.58-39.79-16.13-52.61C176.87,28.65,157.82,21.06,132.64,21.06Z"/>
          <path fill="#09a6e0" d="M132.67,152.87A57.61,57.61,0,1,1,190.3,95.27,57.68,57.68,0,0,1,132.67,152.87Zm0-96.16a38.55,38.55,0,1,0,38.56,38.55A38.6,38.6,0,0,0,132.67,56.72Z"/>
        </svg>
      )
    default:
      return '';
  }
};;
export default Icon;
