import React from 'react';
import { Link } from 'react-router-dom';
import { Crossbar } from '../layout/crossbar';

const links = [
  { label: 'Home', href: '/', },
  { label: 'Inventory', href: '/inventory'},
  { label: 'Sales', href: '/sales'},
  { label: 'Acquisition', href: '/acquisition'},
  { label: 'Financing', href: '/financing'},
  { label: 'Team', href: '/team'},
  { label: 'Contact', href: '/contact'},
  { label: 'Privacy Policy', href: '/privacy-policy'},
].map(link => {
  link.key = `nav-link-${link.href}-${link.label}`
  return link
})

export const MainLinks = (props) => (
  <div className={`links ${props.className || ''}`}>
    {props.children}
    {links.map(({ key, href, label }) => (
      <Link key={key} to={href}>
        <div className="link">{label === 'Home' || props.mobile ? '' : <Crossbar />} {label}</div>
      </Link>
    ))}
  </div>
);
