import React from "react";
import DownloadIcon from "~/src/static/svg/download-arrow.svg";

export const DownloadButton = ({ href, text, onClick }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" onClick={onClick}
    className="btn-border row flex-align-center justify-start"
  >
    <img className="download-arrow" src={DownloadIcon} alt="" />
    <span>{text}</span>
  </a>
)
