import React, { Component } from 'react';
import axios from 'axios';
import { DownloadButton } from './download-button';
import { Modal } from './modal';

export class Specifications extends Component {
  state = {
    showModal: false,
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({showModal: true});
  }
  closeModal = (targetClass) => (e) => {
    if(!e.target.classList.contains(targetClass)) return;
    this.setState({showModal: false});
  }

  render() {
    const { showModal } = this.state;
    return (
      <div>
        <DownloadButton {...this.props} onClick={this.openModal} />
        <Modal open={showModal} closeModal={this.closeModal}>
          <SpecificationsForm {...this.props} closeModal={this.closeModal} />
        </Modal>
      </div>
    )
  }
}

class SpecificationsForm extends Component {
  state = {
    email: '',
    error: '',
    emailExists: null,
  }

  componentDidMount() {
    const email= window.localStorage.getItem('avionmar-specifications-email');
    if(!email) return;
    this.setState({ email })
  }

  handleOnChange = (e) => {
    this.setState({email: e.target.value})
  }

  handleOnSubmit = (e) => {
    e.preventDefault();

    const { email } = this.state;
    // pdf link, airplane name, airplane release year
    const { href, type } = this.props;
    console.log(href, type);

    if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      this.setState({ error: 'Please provide a valid email!' })
      return;
    }

    axios.post('/sendmail/download.php', { email, href, type }).then(({data}) => {
      window.localStorage.setItem('avionmar-specifications-email', email);
    console.log(data);
    
      this.setState({
        email: '',
        error: '',
      })
      window.open(href, '_blank');
    }).catch((e) => {
      console.log(e);
      this.setState({
        error: 'Internal Server Error! We are working to resolve the issue.',
      })
    })
  }

  renderError() {
    const { error } = this.state;
    if(!error) return null;
    return(
      <p className="error-message">
        {error}
      </p>
    )
  }

  render() {
    return (
      <form
        className="modal-form align-center row justify-center"
        onSubmit={this.handleOnSubmit}
      >
        <label>
          Please enter your <b>e-mail</b> address for free download access!
        </label>
        {this.renderError()}
        <input
          type="email"
          placeholder="E-Mail"
          value={this.state.email}
          onChange={this.handleOnChange}
        />
        <button className="btn-border" type="submit">
          download
        </button>
      </form>
    );
  }
}
