import React from 'react';
import './heading.min.css';

export const Heading = ({ text, className, fill, padding, children, height }) => (
  <div className="row justify-start fw heading-container">
    <h2 className={`${className || ''} uppercase fhead block`}>
      {text || children}
    </h2>
  </div>
);

export const HeadingSmall = ({ text, className }) => {
  if (!text) return '';
  return <h6 className={`heading-small ${className || ''}`}>{text}</h6>;
};
