import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/map';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, Footer, HomeSlider } from '../components/main';
import { Container, Heading } from '../components/layout';
import InventorySmall from '../components/inventory/inventory-small';
import LogoLeiste from '../components/logoLeiste';

import { ROOT_URL } from '../redux/root-url';
import MarketReportButton from '../components/static/mr-button';

import { fetchInventories, fetch, MR_BUTTON } from '../redux';
import '../components/logoLeiste/slick.min.css';

class Index extends Component {
  componentDidMount() {
    if (!this.props.mrButton)
      this.props.fetch(MR_BUTTON, ROOT_URL.region('mrButton'));

    this.props.fetchInventories({ home: true });
  }

  renderInventory() {
    if (!this.props.inventory) return;
    let inventory = map(this.props.inventory, (item) => {
      if (!item.home) return null;
      return (
        <InventorySmall className="col-lg-4 col-ms-6" key={item._id} {...item} />
      );
    });
    if (this.props.mrButton)
      inventory.splice(
        2,
        0,
        <MarketReportButton key="mr-button" {...this.props.mrButton} />
      );
    return inventory;
  }

  render() {
    return (
      <MainWrapper>
        <Head title="AVIONMAR - Your expert company specialized in acquisition, sales, brokerage, lease and financing of aircrafts." />
        <Nav />

        <HomeSlider />
        <Container
          container="middle-big"
          className="row flex-align-stretch justify-center"
        >
          <Heading text="Our Inventory" className="primary" fill="#00A7E1" />
          {this.renderInventory()}
        </Container>
        <Container section="bg-primary-dark more-padding" container="fluid">
          <div className="container-middle-big">
            <Heading text="Our Expertise" className="white" fill="#fff" />
          </div>
          <LogoLeiste />
        </Container>
        <Footer />
      </MainWrapper>
    );
  }
}

const mapStateToProps = ({ inventory, mrButton }) => ({ inventory, mrButton });

export default connect(
  mapStateToProps,
  { fetchInventories, fetch }
)(Index);
