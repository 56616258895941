import _ from 'lodash';
import { INVENTORY_LIST } from '../actions';

export default (state = null, action) => {
  switch (action.type) {
    case INVENTORY_LIST:
      return {...state, ..._.mapKeys(action.inventory, '_id')}
    default:
      return state;
  }
}
