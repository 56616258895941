import { config } from '../../config';
import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useRef,
} from 'react';

import { getData, saveData } from '../../utils';
import { CookiePopup, CookiePopupButton } from './CookiePopup';
import { usePageView } from './ReactRouterGA';

import * as ReactGA from '../../utils/ReactGA';

const initialState = {
  necessary: true,
  analytics: false,
  marketing: false,
  noticed: false,
};
const COOKIE_NOTICE_LS = 'cookie-notice-new';

const CookieContext = createContext();
export const useCookiePopup = () => useContext(CookieContext);

export const CookieContextProvider = ({ children }) => {
  const [state, setState] = useState(getData(COOKIE_NOTICE_LS) || initialState);
  const [includePopup, setIncludePopup] = useState(!state.noticed);

  const gaRef = useRef();

  // handle Google Analytics
  useEffect(() => {
    if (state.noticed) saveData(COOKIE_NOTICE_LS, state);
    // eslint-disable-next-line
  }, [state.noticed, state.necessary, state.analytics, state.marketing]);

  useEffect(() => {
    if (state.noticed && state.marketing) {
      // eslint-disable-next-line no-undef
      gtag('consent', 'update', {
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
      });
    } else {
      // eslint-disable-next-line no-undef
      gtag('consent', 'denied', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
      });
    }

    if (state.noticed && state.analytics) {
      // eslint-disable-next-line no-undef
      gtag('consent', 'update', {
        analytics_storage: 'granted',
      });

      if (!gaRef.current) {
        import('../../utils/ReactGA').then((ReactGA) => {
          ReactGA.initialize(config.googleAnalyticsId);
          gaRef.current = ReactGA;
        });
      }
    } else {
      // eslint-disable-next-line no-undef
      gtag('consent', 'update', {
        analytics_storage: 'granted',
      });
    }
  }, [state.noticed, state.necessary, state.analytics, state.marketing]);

  usePageView((pathname) => {
    if (state.analytics) {
      if (gaRef.current) {
        gaRef.current.pageview(pathname);
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }, []);

  return (
    <>
      <CookieContext.Provider
        value={{
          ...state,
          setCookiePreferences: (changes) => setState({ ...state, ...changes }),
          setCookiePopupOpen: (changes) => setIncludePopup(changes),
        }}
      >
        {includePopup ? <CookiePopup /> : <CookiePopupButton />}
        {children}
      </CookieContext.Provider>
    </>
  );
};
