import React from 'react';
import './crossbar.min.css';

export const Crossbar = (props) => {
  if (props.type !== 'big') {
    return (
      <svg
        className={`crossbar-sm ${props.className || ''}`}
        preserveAspectRatio="xminYMin meet"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 57 104"
        style={{
          height: props.height || '24px',
        }}
      >
        <path
          d="M2.08,2.8Q9.19,14.88,16,27.13c4.54,8.16,9,16.37,13.4,24.59s8.75,16.49,13,24.8,8.46,16.65,12.51,25.07Q47.77,89.52,41,77.26c-4.54-8.16-9-16.37-13.4-24.59s-8.75-16.49-13-24.8S6.14,11.21,2.08,2.8Z"
          style={{
            fill: props.fill || '#fff',
          }}
        />
      </svg>
    );
  } else {
    return (
      <svg
        className={`crossbar-sm-2 ${props.className || ''}`}
        preserveAspectRatio="xminYMin meet"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        style={{
          [props.right ? 'right' : 'left']: '-10px',
          height: props.height || '24px',
        }}
      >
        <polygon
          points="64.47 99.66 11.67 0.87 34.08 0.8 86.89 99.59 64.47 99.66"
          style={{
            fill: props.fill || '#fff',
          }}
        />
      </svg>
    );
  }
};
