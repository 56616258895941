import React, { Component } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { InventoryTab } from './inventory-tab';
import ContactForm from '../../static/contact-form';
import Gallery from './gallery';
import { DetailList } from './detail-list';

import tabBalken from '~/src/static/svg/balken-product-single.svg';

export default class InventoryTabs extends Component {
  renderTextArea = (text) => {
    if(!text) return;
    return text.split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    })
  }
  render() {
    return (
      <Tabs className="tab-padding">
        <TabList className="tabs-list row flex-align-center justify-center">
          <Tab>
            <InventoryTab icon="details" />
          </Tab>
          <Tab>
            <InventoryTab icon="gallery" />
          </Tab>
          <Tab>
            <InventoryTab icon="contact" />
          </Tab>
        </TabList>
        <div className="tab-panel-container">
          <div>
            <img className="balken" src={tabBalken} alt="" />
            <div className="tab-panel-container-inner">
              <TabList className="tabs-list-text row flex-align-center justify-center">
                <Tab>
                  <div>Details</div>
                </Tab>
                <Tab>
                  <div>Gallery</div>
                </Tab>
                <Tab>
                  <div>Inquire</div>
                </Tab>
              </TabList>
              <TabPanel>
                <div className="tab-panel details row flex-align-stretch justify-start">

                  <DetailList text={this.props.detailsSpecification} heading="Aircraft Specifications" />
                  <DetailList text={this.props.detailsInterior} heading="Interior" />
                  <DetailList text={this.props.detailsExterior} heading="Exterior" />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="tab-panel">
                  <Gallery images={this.props.gallery} />
                </div>
              </TabPanel>
              <TabPanel>
                <div className="tab-panel">
                  <ContactForm subject={`${this.props.title} - ${this.props.year}`} />
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </Tabs>
    )
  }
}
