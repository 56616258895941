import React from 'react';
import Slider from 'react-slick';
import { HeroImg } from './';
import './home-slider.min.css';

// asset imports
import arrowPrev from '~/src/static/svg/arrow-prev.svg';
import arrowNext from '~/src/static/svg/arrow-next.svg';
import sliderLogoA from '~/src/static/svg/footer-logo-a-slider.svg';
import sliderLogoPlane from '~/src/static/svg/logo-plane.svg';
import sliderImg1 from '~/src/static/images/slider/slide1.jpg';
// import sliderImg2 from '~/src/static/images/slider/slide2.jpg';
import sliderImg7 from '~/src/static/images/team-slider/slide3.jpg';
import sliderImg4 from '~/src/static/images/slider/slide4.jpg';
import sliderImg5 from '~/src/static/images/slider/slide5.jpg';
import sliderImg6 from '~/src/static/images/slider/slide6.jpg';
import new5 from '~/src/static/images/slider/new-4.jpg';
import new2 from '~/src/static/images/slider/new-2.jpg';

import helicopter1 from '~/src/static/images/helicopter1.jpg';
import helicopter2 from '~/src/static/images/helicopter2.jpg';
import helicopter3 from '~/src/static/images/helicopter3.jpg';

export const HomeSlider = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SlideArrow type="next" />,
    prevArrow: <SlideArrow type="prev" />,
  };
  return (
    <Slider {...settings}>
      <div>
        <HeroImg imgUrl={sliderImg1}>
          <SlideHeading className="uppercase heading white shadow-text" fill="#fff">
            Specialized <br /> <span className="thin">on your desires</span>
          </SlideHeading>
          <h5 className="shadow-text">
            We sell business aircrafts in global ambience
          </h5>
        </HeroImg>
      </div>
      <div>
        <HeroImg imgUrl={helicopter1}>
          <SlideHeading className="heading white shadow-text" fill="#fff">
            Specialized <br /> <span className="thin">on your desires</span>
          </SlideHeading>
          <h5 className="shadow-text">
            We sell business aircrafts in global ambience
          </h5>
        </HeroImg>
      </div>
      <div>
        <HeroImg imgUrl={sliderImg4}>
          <SlideHeading className="uppercase heading white shadow-text" fill="#fff">
            Specialized <br /> <span className="thin">on your desires</span>
          </SlideHeading>
          <h5 className="shadow-text">
            We sell business aircrafts in global ambience
          </h5>
        </HeroImg>
      </div>
      <div>
        <HeroImg imgUrl={helicopter2}>
          <SlideHeading className="uppercase heading white shadow-text" fill="#fff">
            Specialized <br /> <span className="thin">on your desires</span>
          </SlideHeading>
          <h5 className="shadow-text">
            We sell business aircrafts in global ambience
          </h5>
        </HeroImg>
      </div>
      <div>
        <HeroImg imgUrl={sliderImg7} />
      </div>
      <div>
        <HeroImg imgUrl={sliderImg5}>
          <SlideHeading className="uppercase heading white shadow-text" fill="#fff">
            Specialized <br /> <span className="thin">on your desires</span>
          </SlideHeading>
          <h5 className="shadow-text">
            We sell business aircrafts in global ambience
          </h5>
        </HeroImg>
      </div>
      <div>
        <HeroImg imgUrl={helicopter3}>
          <SlideHeading className="uppercase heading white shadow-text" fill="#fff">
            Specialized <br /> <span className="thin">on your desires</span>
          </SlideHeading>
          <h5 className="shadow-text">
            We sell business aircrafts in global ambience
          </h5>
        </HeroImg>
      </div>
      {/* <div>
        <HeroImg imgUrl={new5} />
      </div> */}

      <div>
        <HeroImg imgUrl={sliderImg6}>
          <SlideHeading
            className="uppercase heading white shadow-text fhead-logo"
            fill="#fff"
          >
            Specialized <br /> <span className="thin">on your desires</span>
          </SlideHeading>
          <h5 className="shadow-text">
            We sell business aircrafts in global ambience
          </h5>
        </HeroImg>
      </div>
      <div>
        <HeroImg imgUrl={new2} />
      </div>
    </Slider>
  );
};

const SlideHeading = (props) => (
  <div className="slider-heading">
    <div className="slider-logo">
      <img className="triangle" src={sliderLogoA} alt="logo" />
      <img className="plane" src={sliderLogoPlane} alt="logo" />
    </div>
    <h2 className="uppercase block">{props.text || props.children}</h2>
  </div>
);

// needs type prop with either next or prev
const SlideArrow = ({ type, onClick }) => (
  <div
    onClick={onClick || ''}
    className={`slick-nav slick-button-${type || ''}`}
    style={{
      backgroundImage: `url(${type === 'next' ? arrowNext : arrowPrev})`,
    }}
  />
);
