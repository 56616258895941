import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './static/scss/index.min.css';

import { Provider } from 'react-redux';
import { initStore } from './redux';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { CookieContextProvider } from './components/cookie-popup';

const store = initStore();

function isIE() {
  var ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  return is_ie;
}

if (isIE()) {
  document.body.classList.add('is-ie');
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <CookieContextProvider>
        <Router />
      </CookieContextProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
