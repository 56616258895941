import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGoogle,
  faFacebook,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import './social-icon.min.css';

// https://simplesharebuttons.com/html-share-buttons/
const icons = {
  facebook: <FontAwesomeIcon icon={faFacebook} />,
  twitter: <FontAwesomeIcon icon={faTwitter} />,
  google: <FontAwesomeIcon icon={faGoogle} />,
};

const links = {
  facebook: 'http://www.facebook.com/sharer.php?u=',
  twitter: 'https://twitter.com/share?url=',
  google: 'https://plus.google.com/share?url="',
};

export const SocialIcon = (props) => (
  <a
    className={`social-icon ${props.icon}`}
    href={links[props.icon] + props.href + '"'}
    target="_blank"
    rel="noreferrer noopener"
  >
    {icons[props.icon]}
  </a>
);
