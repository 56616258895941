const warn = (s) => {
  console.warn('[react-ga]', s);
};

export const initialize = (id) => {
  const script = document.createElement('script');
  // const script2 = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  // script2.src = `https://www.googletagmanager.com/gtag/js?id=${'UA-147447473-13'}`;
  script.setAttribute('async', true);
  // script2.setAttribute('async', true);
  script.onload = () => {
    // dont really know if this is needed but i think it is
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gId = id;
    window.gtag('config', window.gId);
  };
  // script2.onload = () => {
  //   // dont really know if this is needed but i think it is
  //   window.dataLayer = window.dataLayer || [];
  //   window.gtag = function () {
  //     window.dataLayer.push(arguments);
  //   };
  //   window.gtag('js', new Date());
  //   window.gtag('config', 'UA-147447473-13');
  // };

  document.body.appendChild(script);
  // document.body.appendChild(script2);
};

export const pageview = (path) => {
  if (!path) return warn('path is required in .pageview()');
  if (!window.gId || !window.gtag) {
    warn(
      'ReactGA.initialize must be called first or GoogleAnalytics should be loaded manually'
    );
    return;
  }

  window.gtag('config', window.gId, { page_path: path });
};

export const event = (action, event_category, event_label) => {
  if (!window.gId || !window.gtag) {
    warn(
      'ReactGA.initialize must be called first or GoogleAnalytics should be loaded manually'
    );
    return;
  }
  window.gtag('event', action, {
    event_label: event_label || '',
    event_category: event_category || 'general',
  });
};
