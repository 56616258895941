import React from 'react';
import Slider from 'react-slick';
import { HeroImg } from '../';
import './team-slider.min.css';

import slide3 from '~/src/static/images/team-slider/slide3.jpg';
import slide4 from '~/src/static/images/team-slider/slide4.jpg';
import new2 from '~/src/static/images/slider/new-2.jpg';
// import new4 from '~/src/static/images/slider/new-4.jpg';
import arrowPrev from '~/src/static/svg/arrow-prev.svg';
import arrowNext from '~/src/static/svg/arrow-next.svg';

export const TeamSlider = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <SlideArrow type="next" />,
    prevArrow: <SlideArrow type="prev" />,
  };
  return (
    <Slider {...settings}>
      {/* <div>
        <HeroImg imgUrl={new4} className={'no-slash'} />
      </div> */}
      <div>
        <HeroImg imgUrl={slide3} className={'no-slash'} />
      </div>
      <div>
        <HeroImg imgUrl={new2} className={'no-slash'} />
      </div>
      <div>
        <HeroImg imgUrl={slide4} className={'no-slash'} />
      </div>
    </Slider>
  );
};

// needs type prop with either next or prev
const SlideArrow = ({ type, onClick }) => (
  <div
    onClick={onClick || ''}
    className={`slick-nav slick-button-${type || ''}`}
    style={{
      backgroundImage: `url(${type === 'next' ? arrowNext : arrowPrev})`,
    }}
  />
);
