import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import HCaptcha from '@hcaptcha/react-hcaptcha';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.reCaptchaRef = React.createRef();
  }

  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    error: '',
    success: '',
    token: null,
  };

  static getDerivedStateFromProps = (nextProps, prevState) => {
    var newState = prevState;
    for (var prop in nextProps) {
      if (nextProps[prop] !== newState[prop]) {
        newState[prop] = nextProps[prop];
      }
    }
    if (!_.isEqual(newState, prevState)) return newState;
    return null;
  };

  handleOnChange = (e) => {
    const { target } = e;
    const { name, type } = target;
    const value = type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleOnSubmit = (e) => {
    e.preventDefault();
    const { name, email, subject, message, privacyPolicy, token } = this.state;

    if (!token) {
      this.setState({
        error: 'Bestätigen Sie das reCaptcha!',
      });
      return;
    }

    if (!privacyPolicy) {
      this.setState({
        error: 'You have to accept our Privacy Policy to use this form!',
      });
      return;
    }
    if (!name || !email || !subject || !message) {
      this.setState({ error: 'Please fill out all fields!' });
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      this.setState({ error: 'Please provide a valid email!' });
      return;
    }

    axios
      .post('/sendmail/', {
        name,
        email,
        subject,
        message,
        'h-captcha-response': token,
      })
      .then(() => {
        this.reCaptchaRef.current.resetCaptcha();
        this.setState({
          name: '',
          email: '',
          subject: '',
          message: '',
          error: '',
          success: 'The message has successfully been send',
          token: null,
        });
      })
      .catch(() => {
        this.reCaptchaRef.current.resetCaptcha();
        this.setState({
          error: 'Internal Server Error',
          success: '',
        });
      });
  };

  handleCaptcha = (value) => {
    if (!value) return;
    this.setState({ token: value });
  };

  renderMessage() {
    const { success, error } = this.state;
    if (error || success) {
      return (
        <p className={`message ${error ? 'error' : 'success'}`}>
          {error || success}
        </p>
      );
    } else {
      return null;
    }
  }

  render() {
    console.log('rendered');

    return (
      <form
        className="row flex-align-stretch justify-start"
        onSubmit={this.handleOnSubmit}
      >
        <div className="col-5">
          <fieldset>
            <input
              name="name"
              value={this.state.name}
              placeholder="Full Name"
              onChange={this.handleOnChange}
            />
          </fieldset>
          <fieldset>
            <input
              name="email"
              value={this.state.email}
              placeholder="Email"
              onChange={this.handleOnChange}
            />
          </fieldset>
          <fieldset>
            <input
              name="subject"
              value={this.state.subject}
              placeholder="Subject"
              onChange={this.handleOnChange}
            />
          </fieldset>
        </div>
        <div className="col-7">
          <textarea
            name="message"
            value={this.state.message}
            placeholder="Message"
            onChange={this.handleOnChange}
          />
        </div>
        <div className="col-12">
          <label>
            <input
              type="checkbox"
              name="privacyPolicy"
              onChange={this.handleOnChange}
            />
            Privacy Policy
          </label>
          <p className="pp-form">
            I give consent to use this information to send additional emails and
            communication as described in our
            <Link to="/privacy-policy">
              <button className="link primary">&nbsp;Privacy Policy</button>
            </Link>
            .
          </p>
        </div>
        {this.renderMessage()}
        <div className="col-12 row flex-align-center justify-start">
          <button
            className="btn-border"
            style={{ marginLeft: '6px' }}
            type="submit"
          >
            Submit
          </button>
          <div
            className="captcha"
            style={{
              padding: '0.7rem',
              paddingLeft: '0',
              marginLeft: '12px',
            }}
          >
            {/* <ReCAPTCHA
              ref={this.reCaptchaRef}
              sitekey="6LfJQ1sUAAAAAPEVNJ5cIBRHT3AMdFeeF-teSZY1"
              onChange={this.handleCaptcha}
            /> */}
            <HCaptcha
              ref={this.reCaptchaRef}
              sitekey={
                process.env.NODE_ENV === 'development'
                  ? '10000000-ffff-ffff-ffff-000000000001'
                  : '05289560-270f-4dd9-be93-dd6ad62202b0'
              }
              onVerify={(token) => this.handleCaptcha(token)}
            />
          </div>
        </div>
      </form>
    );
  }
}
export default ContactForm;
// export default withRedux(initStore, null)(ContactContact)
