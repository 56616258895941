import React, { Component } from 'react';
import axios from 'axios';
import { ROOT_URL } from '../redux/root-url';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, TeamSlider, Footer } from '../components/main';
import { Container } from '../components/layout';
import { TeamMember } from '../components/static/team-member';

import aboutHeading from '~/src/static/svg/about-us.svg';
import team from '~/src/static/images/team-n.jpg';

class Team extends Component {
  state = {
    team: null,
  };

  componentDidMount() {
    axios.post(ROOT_URL.collection('team')).then(({ data }) => {
      this.setState({
        team: data.entries,
      });
    });
  }

  renderTeamMembers() {
    if (!this.state.team) return;

    return this.state.team.map((item) => {
      return <TeamMember className="" key={item._id} {...item} />;
    });
  }
  render() {
    return (
      <MainWrapper>
        <Head
          title="Team | AVIONMAR - ABOUT US"
          description="Avionmar is a boutique company, specialized in financing, acquisition, sales and lease of business aircraft, helicopters and commercial aircraft."
        />
        <Nav />

        <TeamSlider />

        <Container
          className="row flex-align-start justify-start nop"
          section="nop pt"
        >
          <img className="col-8 nop" src={aboutHeading} alt="" />
        </Container>
        <Container
          className="row flex-align-stretch justify-space-between"
          section="nop"
        >
          <div className="col-4 row flex-align-start justify-left bg-primary-dark team-textblock">
            <h5 className="fhead fw primary uppercase bold">What we do</h5>
            <p>
              Avionmar is a boutique company, specialized in financing, acquisition,
              sales and lease of business aircraft, helicopters and commercial
              aircraft. For selected clients, Avionmar also acts as a family office
              providing a wide range of tailor-made services around the aircraft.
            </p>
            <hr />
            <p>
              Each transaction is structured strictly and precisely to achieve the
              highest success for the customer, which is why Avionmar only executes
              selected acquisition, financing or sales projects at any given time to
              ensure we provide an outstanding level of service to our customers.
            </p>
            <hr />
            <p>
              As a result, Avionmar has an above-average number of long term
              clients. From buying the first aircraft, to finding the most suitable
              operator, to sourcing the next new aircraft - often with the provision
              of a financing package - Avionmar offers 100% support to our
              customers.
              <br /> <br />
              <br />
              <br />
            </p>
            <h5 className="fhead fw primary bold uppercase">Who we are</h5>
            <p>
              Avionmars team can look back on more than 20 years of experiance in
              aircraft financing and has established very close relations with
              international banks, OEMs, aircraft leasing companies and operators.
              <br />
            </p>
          </div>
          <div className="col-8  row flex-align-start justify-start team-container">
            <img src={team} />
            <div className="team-grid">{this.renderTeamMembers()}</div>
          </div>
        </Container>
        <Container />
        <Footer />
      </MainWrapper>
    );
  }
}
export default Team;
