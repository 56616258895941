import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import MainWrapper from './components/mainWrapper';
import Index from './pages';
import InventorySingle from './pages/inventory-single';
import Inventory from './pages/inventory';
import MarketReports from './pages/market-reports';
import Sales from './pages/sales';
import Acquisition from './pages/acquisition';
import Financing from './pages/financing';
import Team from './pages/team';
import Contact from './pages/contact';
import Imprint from './pages/imprint';
import PrivacyPolicy from './pages/privacy-policy';

class Router extends Component {
  render() {
    return (
      <>
        <Route path="/" component={MainWrapper} />
        <Switch>
          <Route path="/inventory/:id" component={InventorySingle} />
          <Route path="/inventory" component={Inventory} />
          <Route path="/market-reports" component={MarketReports} />
          <Route path="/sales" component={Sales} />
          <Route path="/acquisition" component={Acquisition} />
          <Route path="/financing" component={Financing} />
          <Route path="/team" component={Team} />
          <Route path="/contact" component={Contact} />
          <Route path="/imprint" component={Imprint} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/" component={Index} />
        </Switch>
      </>
    );
  }
}

export default Router;
