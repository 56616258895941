import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../static/logo';
import { MainLinks } from './links';
// import Language from './language';

export const Nav = class extends Component {
  state = {
    activeClass: '',
    mobileNav: false,
    open: false,
  }

  componentDidMount() {
    this.handleScroll();
    this.handleResize();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleResize = () => {
    clearTimeout(this.resizeEnd);
    this.resizeEnd = setTimeout(() => {
      const windowWidth = window.innerWidth;
      if(windowWidth <= 1370 ) {
        this.setState({
          mobileNav: true
        })
      } else {
        this.setState({
          mobileNav: false
        })
      }
    }, 100);
  }

  handleScroll = (e) => {
    const wScroll = window.scrollY;
    if (wScroll > 0) {
      this.setState({
        activeClass: 'standard',
      })
    } else {
      this.setState({
        activeClass: '',
      })
    }
  }

  toggleNav = () => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    const { mobileNav, activeClass, open } = this.state;
    return (
      <nav className={`
        row flex-align-start justify-start ${activeClass} ${mobileNav ? 'mobile' : ''} ${open ? 'open': ''}
      `}>
        {mobileNav ? <div
          className={`mobile-menu-button ${open ? 'close' : ''}`}
          onClick={this.toggleNav}
        >
          <div></div><div></div><div></div>
        </div> : ''}
        <Link to="/">
          <Logo className={activeClass} />
        </Link>
        <MainLinks
          mobile={mobileNav}
          className={`
            flex-align-center
            ${mobileNav ? 'column justify-center' : 'col row justify-end'}
          `}
          language={true}
        >
          {/* <Language mobile={mobileNav} /> */}
        </MainLinks>
      </nav>
    );
  }
}
