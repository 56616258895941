import React, { Component } from 'react';

import axios from 'axios';
import { ROOT_URL, API_URL } from '../redux/root-url';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, HeroImg, Footer } from '../components/main';
import { Container, Heading } from '../components/layout';
import heroImg from '~/src/static/images/inventory.jpg';


const MarketReport = (props) => (
  <div className="col-lg-4 col-6 mr__item inventory-small no-anim">
    <button onClick={props.openPopup(`${API_URL}/${props.pdf}`)}>
      <figure>
        <img src={API_URL + '/' + props.img.path || ''} alt="" />
        <figcaption className="">
          <h6 className="uppercase align-center shadow-text">
            <span>{props.title}</span>
          </h6>
          <div className="details">
            <p className="uppercase bold">Click for <br />full report</p>
          </div>
        </figcaption>
      </figure>
    </button>
  </div>
)

class MarketReports extends Component {
  state = {
    reports: null,
  }
  componentDidMount() {
    console.log('hi');
    axios.get(ROOT_URL.collection('marketReports')).then(({data}) => {
      console.log(data);
      this.setState({reports: data})
    })
  }
  openPopup = (url) => (e) => {
    e.preventDefault();
    window.open(
      url,'popUpWindow',
      'left=10,top=10,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes'
    )
  }

  renderHelper = () => {
    if(!this.state.reports) return;
    return this.state.reports.entries.map((item, i) => (
      <MarketReport key={i} {...item} openPopup={this.openPopup} />
    ))
  }
  render() {
    return(
      <MainWrapper>
        <Head title="Market Reports | AVIONMAR" />
        <Nav />
        <HeroImg
          imgUrl={heroImg}
          className={'subpage'}
        >
          <h1>Market Reports</h1>
        </HeroImg>

        <Container className="row flex-align-start justify-left" section="more-padding">
          <Heading text="Market Reports" className="primary" fill="#00A7E1" />
          {this.renderHelper()}
        </Container>
        <Footer />
      </MainWrapper>
    )
  }
}
export default MarketReports;
