import React from 'react';

export const Container = (props) => (
  <section className={`${props.section || ''}`}>
    <div className={`
      ${props.className || ''}
      ${props.container ? `container-${props.container}` : 'container'}
    `}>
      {props.children}
    </div>
  </section>
)
