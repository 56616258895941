import React, { Component } from 'react';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, HeroImg, Footer } from '../components/main';
import { Container } from '../components/layout';

import heroImg from '~/src/static/images/financing.jpg';
import fin1 from '~/src/static/images/financing1.jpg';
import jetleasefinance from '~/src/static/images/jetleasefinance.png';

class Financing extends Component {
  render() {
    return (
      <MainWrapper>
        <Head
          title="Financing | AVIONMAR - Aircraft FINANCING"
          description="If you are looking for financial leasing, a loan or a wet or dry lease for your aircraft, we can provide you with the right financing."
        />
        <Nav />

        <HeroImg imgUrl={heroImg} className={"subpage"}>
          <h1>Financing</h1>
        </HeroImg>

        <Container className="row flex-align-start justify-left financing">
          <div className="col-6 ">
            <div className="col-12">
              <h4 className="fhead">
                Aircraft <br />
                <span className="span2 thin uppercase">Financing</span>
              </h4>
            </div>
            <div className="col-12">
              <p>
                If you are looking for financial leasing, a loan or a wet or
                dry lease for your aircraft, we can provide you with the
                right financing. With more than 20 years of experience in
                aircraft financing and a background in banking, our team has
                established very close relations with international banks,
                aircraft leasing companies and operators.
                <br />
                <br />
                AVIONMAR can provide you with a selection of different
                aircraft in various categories available for dry or wet
                lease. Please let us know your specific requirements, the
                aircraft type you are looking for as well as the mission
                profile and we will provide you with the perfect aircraft
                for your utilization.
              </p>
            </div>
            <div className="col-12">
              <div className="plus-block">
                <h6 className="plus small">Jet Lease Finance</h6>
                <div className="row flex-align-start justify-start">
                  <p className="col-8">
                    We have established close relations to all major
                    European Banks and either provide financing with them or
                    via our affiliate Jet Lease Finance.
                    <br />
                    <br />
                    <strong>Check out:</strong> &nbsp;
                    <a
                      href="http://www.jetleasefinance.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="primary link"
                    >
                      www.jetleasefinance.com
                    </a>
                  </p>
                  <a href="http://www.jetleasefinance.com" target="blank">
                    <img
                      className="col-4 jetleasefinance"
                      src={jetleasefinance}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="col-12 nop">
              <img src={fin1} alt="" />
            </div>
          </div>
        </Container>

        <Footer />
      </MainWrapper>
    );
  }
}
export default Financing;
