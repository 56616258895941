import React from 'react';

export const FancyBarSep = (props) => (
  <div className={`${props.className || ''} fbs row flex-align-center justify-center`}>
    <div className="fbs-left col-md-6 row justify-end">
      {props.left}
    </div>
    <svg className="crossbar" preserveAspectRatio="xMidYMin meet" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <polygon points="64.47 99.66 11.67 0.87 34.08 0.8 86.89 99.59 64.47 99.66" fill="#fff"/>
    </svg>
    <div className="fbs-right col-md-6 row justify-start">
      {props.right}
    </div>
  </div>
)
