import React, { Component } from 'react';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, HeroImg, Footer } from '../components/main';
import { Container } from '../components/layout';

import heroImg from '~/src/static/images/aquisition.jpg';
import acq2 from '~/src/static/images/aquisition2.jpg';

class Aquisition extends Component {
  render() {
    return (
      <MainWrapper>
        <Head
          title="Acquisition | AVIONMAR - Exclusive Aircraft Marketing & ACQUISITION AGREEMENTS"
          description="Experience shows there are reasons for granting an exclusive sale or acquisition mandate as a client’s best choice."
        />
        <Nav />

        <HeroImg imgUrl={heroImg} className={"subpage"}>
          <h1>Acquisition</h1>
        </HeroImg>

        <Container className="financing row flex-align-start justify-left">
          <div className="col-12">
            <div className="col-12">
              <h4 className="fhead">
                Exclusive Aircraft Marketing & <br />
                <span className="span2 thin uppercase">
                  Acquisition Agreements
                </span>
              </h4>
            </div>
          </div>
          <div className="col-6">
            <div className="col-12">
              <p>
                Experience shows there are reasons for granting an exclusive
                sale or acquisition mandate as a client’s best choice.
                Several brokers - either entitled or working on their own -
                selling your aircraft and probably approaching the same
                buyer on a non-exclusive basis, will create the impression
                that there are more buyers than there are. Likewise, if
                several brokers are seeking an aircraft on your behalf, the
                seller of the “right” aircraft will be led to believe that
                demand is greater than it actually is, pushing up the price
                you will pay as a consequence.
              </p>
            </div>
            <div className="col-12">
              <div className="plus-block">
                <h6 className="plus uppercase">
                  Trading up/down
                  <br />
                  <span className="thin">for your next aircraft</span>
                </h6>
                <p>
                  When travel requires change, you may need to change to
                  another aircraft. This switch can be made very easy as and
                  when it happens by experience, communication and a
                  comprehensive network of contacts. Elements of the sale
                  and acquisition process detailed in the preceding are our
                  keys to making this work as you need it too. That is the
                  service we provide as your broker.
                </p>
              </div>
            </div>
            <div className="col-12">
              <h6>Purchasing your aircraft</h6>
              <p>
                We at AVIONMAR have access to more than just publicly
                advertised aircraft. Through years of building our network,
                we have developed extensive contacts to brokers with clients
                who do not advertise their aircraft on the open market, as
                well as to management and maintenance companies and
                manufacturers.
                <br />
                <br />
                These off-market aircraft are often less exposed to the
                pressures of pricing-by-demand and can represent outstanding
                value for money. Through our experience and contacts we can
                also help you get the best price when acquiring a new
                aircraft from a manufacturer as a first-time buyer. This
                process involves:
              </p>
              <br />
              <ul className="list">
                <li>
                  Comparing aircraft performance (if needed) to help
                  identify the ideal aircraft for you
                </li>
                <li>Reviewing the market for availability and pricing</li>
                <li>
                  Utilizing our extensive personal broker contact network
                  for off-market aircraft at attractive prices and locating
                  the best deal for your preferred type and model
                </li>
                <li>
                  Submitting an offer on your behalf based on the best
                  possible pricing
                </li>
                <li>
                  Assisting with offer and contract negotiation (either
                  independently or with support from our network of lawyers)
                </li>
                <li>
                  Managing and supervising the pre-buy inspection process
                </li>
                <li>
                  Managing the documentation for sales completion with the
                  Civil Aviation Authority
                </li>
                <li>Managing the closing process</li>
                <li>
                  Ensuring privacy and discretion throughout the transaction
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6">
            <div className="col-12">
              <img src={acq2} alt="" />
            </div>
            <div className="col-12">
              <h6>Add us to your team!</h6>
              <p>
                Many pilots say the distraction of trying to be a
                salesperson seriously conflicts with their core priorities
                and responsibilities, which is to comply with technical and
                regulatory updates on which they must focus and provide for
                your safe travels. Most don’t feel adept at taking many
                dozens of phone calls for pricing, specifications, nor
                qualifying buyers, sellers, brokers, and offers. And with
                many voicemails and eMails stacking up daily, it’s easy to
                delay or miss contacting the right buyer. In contrast,
                AVIONMAR has only one single focus --- selling or acquiring
                your aircraft. And with our tools, experience and network,
                we can stay in touch with prospective buyers, as well as
                provide guidance on pricing and availability of aircraft.
                <br />
                <br />
                AVIONMAR takes the distraction and workload off your desks
                and lets all of you focus on your core business priorities.
              </p>
            </div>
            <div className="col-12">
              <h6>Why mandate us?</h6>
              <p>
                Core business skills and priorities are the keywords. You
                and your pilots or your management company are experts at
                the missions you face every day - business profitability and
                safe aircraft travel respectively. With AVIONMAR as your
                broker you receive the benefit of our years of market
                knowledge, our huge network to OEMs, maintenance facilities,
                banks and other brokers, our transaction experience,
                aircraft contract negotiation experience and skills. We
                filter out the many tire-kickers and pricing researchers and
                concentrate our focus on your transaction. This is the
                expertise we exhibit - every day, in every transaction.
              </p>
            </div>
          </div>
        </Container>
        <Footer />
      </MainWrapper>
    );
  }
}
export default Aquisition;
