import React from 'react';
import ScrollToTop from '../routing/scroll-to-top';

const MainWrapper = ({ children }) => (
  <>
    {/* <CookiePopup /> */}
    <ScrollToTop />
    {children}
  </>
);

export default MainWrapper;
