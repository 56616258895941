import React, { useEffect, useState } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import mapPlaceholder from '../../static/images/maps-placeholder.jpg';

const pos = { lat: 48.2064992, lng: 16.3755594 };
const Map = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap defaultZoom={10} defaultCenter={pos}>
      {props.isMarkerShown && <Marker position={pos} />}
    </GoogleMap>
  ))
);

const MapComponent = (props) => {
  const [accepted, setAccepted] = useState(
    window.localStorage.getItem('map-accepted') || false
  );
  // useEffect(() => {
  //   var originalFunction = document.createElement;
  //   document.createElement = function(tag, attributes) {
  //     if (tag === 'link') return null;
  //     return originalFunction.call(document, tag);
  //   };
  // });

  return accepted ? (
    <Map {...props} />
  ) : (
    <div
      class="map-plaecholder row justify-center flex-align-center"
      style={{
        height: 400,
        width: '100%',
        backgroundImage: `url(${mapPlaceholder})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <button
        class="btn-border"
        onClick={() => {
          window.localStorage.setItem('map-accepted', true);
          setAccepted(true);
        }}
      >
        Klicke hier, um Marketing-Cookies zu
        <br /> akzeptieren und diesen Inhalt zu aktivieren.
      </button>
    </div>
  );
};

export default MapComponent;
