import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import * as ReactGa from '../../utils/ReactGA';
import ReactGA from 'react-ga';

import { useCookiePopup } from './Context';

export const ReactRouterGa = ({ children }) => {
  const { pathname } = useLocation();
  const { noticed, analytics } = useCookiePopup();

  useEffect(() => {
    if (!noticed || !analytics) return;
    ReactGa.pageview(pathname);
    ReactGA.pageview(pathname);
  }, [pathname, noticed, analytics]);

  return children || null;
};

export const usePageView = (callback = () => {}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    callback(pathname);
  }, [pathname, callback]);

  return null;
};
