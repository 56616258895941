import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API_URL, SERVER_URL } from '../redux/root-url';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, Footer } from '../components/main';
import { Container } from '../components/layout';
import InventoryBig from '../components/inventory/inventory-big';
import InventoryTabs from '../components/inventory/tabs';

import { fetchInventories } from '../redux';
import { translate } from '../components/main/language';


const InventorySingleMain = ({ inventory: aircraft }) => {
  let { path } = aircraft.thumbnail;
  // make sure the url will be setup the right way >_>
  path = path.indexOf('/') === 0 ? path : `/${path}`
  return (
    <MainWrapper>
      <Head
        title={aircraft.title + " | AVIONMAR"}
        ogImage={API_URL + path}
        url={`${SERVER_URL}/inventory/${aircraft._id}`}
      />
      <Nav />
      <Container container="middle-big" className="nop">
        <InventoryBig {...aircraft} />
        <InventoryTabs {...aircraft} />
      </Container>
    <Footer />
    </MainWrapper>
  )
}

const InventorySingleT = translate(
  (props, lng) => {
    let object = props;
    const test = props.inventory.map(item => {
      for(const key in item) {
        if(key.search(lng) !== -1)
          item[key.replace(`_${lng}`, '')] = item[key];
      }
      return item;
    })
    return { ...object, inventory: test, lng };
  }
)(InventorySingleMain);

class InventorySingle extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    if(!this.props.inventory)
      this.props.fetchInventories({ _id: id });
  }

  render() {
    if(!this.props.inventory) return <div></div>;
    const { id } = this.props.match.params;
    return <InventorySingleT inventory={this.props.inventory[id]} />;
  }
}

const mapStateToProps = ({inventory, mrButton}) => ({inventory, mrButton})

export default connect(
  mapStateToProps,
  { fetchInventories }
)(InventorySingle);
