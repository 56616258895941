import React from 'react';
import { Crossbar } from '../layout';
import { API_URL, SERVER_URL } from '../../redux/root-url';
import { DetailPreview } from './detail-preview';
import { SocialIcon } from '../static/social-icon';
import './inventory-big.min.css';
import { Specifications } from './specifications';

const InventoryBig = (props) => (
  <div className="inventory-big nop container-fluid row flex-align-start justify-start">
    <div
      className={`
      ${props.className || ''} container-big nop
      row flex-align-stretch justify-start
    `}
    >
      <div
        className="col-ms-5 nop img-container plane-img"
        style={{
          backgroundImage: `url(${API_URL}${
            props.thumbnail.path.indexOf('/') === 0
              ? props.thumbnail.path
              : `/${props.thumbnail.path}`
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '450px',
        }}
      />
      <div className="col-ms-7 text-container">
        <Crossbar
          type="big"
          height="calc(100% - 20px)"
          fill="rgba(162, 169, 173, .09)"
        />
        <div className="product-heading row flex-align-start justify-start">
          <h2 className="uppercase fw">{props.title}</h2>
          <div className="col-5">
            <h4>
              <span>{props.brand}</span>
              <br />
              {props.year}
            </h4>
            {props.specifications ? (
              <Specifications
                href={`${API_URL}/${props.specifications}`}
                text="Specifications"
                type={`${props.title} - ${props.year}`}
              />
            ) : (
              ''
            )}
            <div className="social-icons">
              <h6 className="secondary">Share</h6>
              <SocialIcon
                icon="facebook"
                href={`${SERVER_URL}/inventory/${props._id}`}
              />
              <SocialIcon
                icon="twitter"
                href={`${SERVER_URL}/inventory/${props._id}`}
              />
              <SocialIcon
                icon="google"
                href={`${SERVER_URL}/inventory/${props._id}`}
              />
            </div>
          </div>
          <div className="col-lg-7">
            <DetailPreview {...props} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InventoryBig;
