import React from 'react';
import './logo.min.css';

const Logo = (props) => (
  <svg
    preserveAspectRatio="xMinyMin meet"
    className={`logo ${props.className || ''}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 566.93 149.09"
  >
    <g>
      <polygon
        className="cls-1"
        points="149.43 69.58 131.45 32.65 117.17 32.65 149.43 96.29 181.51 32.65 167.41 32.65 149.43 69.58"
      />
      <rect className="cls-1" x="191.91" y="32.65" width="12.78" height="62.58" />
      <path
        className="cls-1"
        d="M251.67,31.59A32.35,32.35,0,1,0,284,63.94,32.31,32.31,0,0,0,251.67,31.59Zm0,52.36c-10.93,0-19.48-9.08-19.48-20s8.55-20,19.48-20,19.48,9.08,19.48,20S262.6,83.95,251.67,83.95Z"
      />
      <polygon
        className="cls-1"
        points="339.28 68 298.64 31.59 298.64 95.23 311.42 95.23 311.42 59.89 352.06 96.29 352.06 32.65 339.28 32.65 339.28 68"
      />
      <polygon
        className="cls-2"
        points="372.15 31.59 372.15 95.23 378.85 95.23 378.85 49.58 402.03 76.72 425.21 49.58 425.21 95.23 431.91 95.23 431.91 31.59 402.03 66.59 372.15 31.59"
      />
      <path
        className="cls-2"
        d="M535.38,66.14H538c11.37,0,18.51-6.61,18.51-16.75S549.48,32.65,538,32.65H519.33V95.23H526V65.79l31.91,29.44h9ZM526,38.91h11.19c7.93,0,12.51,3.53,12.51,10.49S545.16,60,537.23,60H526Z"
      />
      <polygon
        className="cls-2"
        points="444.51 95.23 452.26 95.23 475.62 46.11 498.98 95.23 506.73 95.23 475.62 30.98 444.51 95.23"
      />
      <rect className="rect" y="119" x="20" width="100" height="10" />
      <path
        className="cls-1"
        d="M0,128.94H24.78l20.36-28.16,4.37-1.39h3.84v9.55a1.39,1.39,0,0,0,1.12,1.38c.21,1.18.86,4.88,1.61,8.2a.54.54,0,0,0,.28.36A4.15,4.15,0,0,0,59,119c.64.41,2.72,1.77,5.19,3.49,1.35,7.56,2.24,10.35,2.64,11.34L53,143.66a.54.54,0,0,0-.22.4l-.35,4.45a.53.53,0,0,0,.2.46.53.53,0,0,0,.5.09L68.64,144l15.5,5.09a.51.51,0,0,0,.17,0,.54.54,0,0,0,.53-.58l-.35-4.45a.54.54,0,0,0-.22-.4l-13.83-9.83c.4-1,1.29-3.77,2.64-11.34,2.47-1.72,4.55-3.08,5.19-3.49a4.18,4.18,0,0,0,2.66-.1.54.54,0,0,0,.28-.36c.75-3.33,1.4-7,1.61-8.2a1.39,1.39,0,0,0,1.12-1.38V99.39h3.84l4.53,1.44,20.21,28.11h24.78L68.77,0Zm54.42-20V99.39h6v9.53a.42.42,0,0,1-.13.31.4.4,0,0,1-.27.09H54.94C54.78,109.31,54.42,109.27,54.42,108.94Zm4.21,9a3.47,3.47,0,0,1-1.57,0c-.68-3-1.26-6.31-1.5-7.63h4.27Zm21.59,0a3.49,3.49,0,0,1-1.57,0l-1.2-7.59h4.27C81.48,111.71,80.9,115,80.22,118Zm2.64-9.07c0,.32-.36.36-.51.37H77.24a.34.34,0,0,1-.37-.36V99.39h6ZM75.2,59.25V76.37a.54.54,0,0,0,.21.42l12,9.24c.26.14,25.58,13.75,28,15.09l3.58,7.29.13.82-2.21-1.69a.54.54,0,0,0-.16-.08L88,98.34l-.16,0H75.8v10.77a1.23,1.23,0,0,0,.53,1.08l1.27,8c-.73.47-2.83,1.85-5.32,3.58a.54.54,0,0,0-.22.35c-1.75,9.9-2.71,11.57-2.74,11.62a.54.54,0,0,0,.13.74l14,9.95.27,3.4-14.9-4.9-.17,0-.17,0-14.89,4.9.27-3.4,14-9.94a.56.56,0,0,0,.18-.19.55.55,0,0,0,0-.55c0-.07-1-1.74-2.75-11.64a.53.53,0,0,0-.22-.35c-2.48-1.73-4.58-3.11-5.31-3.58l1.26-8a1.27,1.27,0,0,0,.53-1.1V98.32H49.43l-.16,0-28.75,9.12a.54.54,0,0,0-.16.08l-2.21,1.69.13-.82,3.58-7.29C24.31,99.78,49.63,86.17,50,86l11.85-9.15a.55.55,0,0,0,.24-.32.53.53,0,0,0,0-.14V59.27c0-.11.54-10.73,5.92-16.51a.79.79,0,0,1,.69-.31.76.76,0,0,1,.6.32C74.66,48.54,75.2,59.17,75.2,59.25Z"
      />
    </g>
  </svg>
);

export default Logo;
