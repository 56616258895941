import React, { Component, useEffect } from 'react';

import MainWrapper from '../components/mainWrapper';
import { Head, Nav, HeroImg, Footer } from '../components/main';
import ContactForm from '../components/static/contact-form';
import { Container, FancyBarSep, Heading } from '../components/layout';
import MapComponent from '../components/static/map';
import Icon from '../components/static/icon';
import heroImg from '~/src/static/images/contact.jpg';

const Contact = () => {
  return (
    <MainWrapper>
      <Head
        title="Contact | AVIONMAR - REACH OUT YOUR WINGS"
        description="Reach out your wings and Kontakt us"
      />
      <Nav />

      <HeroImg imgUrl={heroImg} className={'subpage'}>
        <h1>Contact</h1>
      </HeroImg>
      <Container>
        <Heading
          text="Reach out your wings"
          className="primary nom"
          fill="#00A7E1"
          padding="0px"
        />
      </Container>

      <Container container="middle" section="nop">
        <ContactForm />
      </Container>
      <Container />
      <FancyBarSep
        className="bg-primary"
        left={
          <div className="row justify-start flex-align-center">
            <Icon icon="marker" className="icon" />
            <div className="text-box">
              <p>
                <b>AUSTRIA Headoffice</b> <br />
                AvionMar GmbH <br />
                Hegelgasse 8/14 <br />
                1010 Wien
              </p>
            </div>
          </div>
        }
        right={
          <div className="row justify-end flex-align-center">
            <Icon icon="phone" className="icon" />
            <div className="text-box">
              <p>
                <a href="tel:+43 4272 44 7 66">Phone: +43 4272 44 7 66</a> <br />
                Fax.: +43 4272 44 7 66 - 50 <br />
                <a href="mailto:office@avionmar.com"> office@avionmar.com</a> <br />
              </p>
            </div>
          </div>
        }
      />

      <Container className="financing row justify-center nop">
        <div className="col-8">
          <div className="plus-block">
            <h6 className="plus small">Further Locations</h6>
            <div className="fw row">
              <div className="col-6">
                <p>
                  <b>AUSTRIA Backoffice / Administration</b>
                  <br />
                  AVIONMAR GmbH <br />
                  Hauptstraße 166 <br />
                  9210 Pörtschach
                </p>
              </div>
              {/* <div className="col-6">
                  <p>
                    <b>IRELAND - AVIONMAR AVIATION LTD</b>
                    <br />
                    First Floor Old Terminal Building <br />
                    Shannon Airport <br />
                    Co. Clare
                    <br />
                    Ireland - V14 EE06
                  </p>
                </div> */}
            </div>
          </div>
        </div>
      </Container>

      <MapComponent
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCqp4TqJ2J0vz7jtKtrf6l1bpqewCPGLCs&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
      <Footer />
    </MainWrapper>
  );
};

export default Contact;
