import React from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../redux/root-url';

const InventorySmallContent = (props) => (
  <figure>
    <img src={API_URL + '/' + props.thumbnail.path || ''} alt={props.title} />
    <figcaption className="">
      <h6 className="uppercase align-center shadow-text">
        <span translate="no" class="notranslate">
          {props.title}
        </span>
      </h6>
      <div className="details">
        <p className="uppercase bold">{props.brand}</p>
        <p>{props.year}</p>
      </div>
    </figcaption>
  </figure>
);

const AttentionBox = (props) => {
  const text = props.sold
    ? 'sold'
    : props.salePending
    ? 'sale pending'
    : props.sale
    ? 'new to market'
    : props.wanted
    ? 'wanted'
    : '';
  return <div className="attention-box">{text}</div>;
};

const InventorySmall = (props) => {
  if (props.sold || props.salePending || props.detailsDisabled) {
    return (
      <div className={`${props.className} inventory-small no-anim`}>
        {!props.detailsDisabled || props.sale || props.wanted ? (
          <AttentionBox {...props} />
        ) : (
          ''
        )}
        <InventorySmallContent {...props} />
      </div>
    );
  }
  return (
    <Link
      to={`inventory/${props._id}`}
      className={`${props.className} inventory-small`}
    >
      {props.sale || props.wanted ? <AttentionBox {...props} /> : ''}
      <InventorySmallContent {...props} />
    </Link>
  );
};

export default InventorySmall;
