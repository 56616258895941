import React from 'react';

import { HeadingSmall } from '../../layout';
import ReactMarkdown from 'react-markdown';

export const DetailList = props => {
  if(!props.text) return '';
  return (
    <div className="detail-box col-lg-4 col-6">
      <HeadingSmall text={props.heading || ''} className="primary" />
      <ReactMarkdown
        source={props.text}
        renderers={{
          list: List,
          paragraph: Paragraph
        }}
      />
    </div>
  )
}
const List = (props) => (
  <ul className="list">{props.children}</ul>
)
const Paragraph = (props) => (
  <p className="mp">
    {props.children}
  </p>
)
// {/* <ul className="list">
// // {props.text.split('\n').map((item, key) => {
// //   if(!item) return <br key={key} />;
// //   return (
// //     <li key={key}>{item}<br/></li>
// //   );
// // })}
// </ul> */}
