import React, { Component } from 'react';
import Lightbox from 'react-images';
import { API_URL } from '../../../redux/root-url';

class Gallery extends Component {
  state = {
    lightboxIsOpen: false,
    currentImage: 0,
  }
  openLightbox = (index, event) => {
		event.preventDefault();
		this.setState({
			currentImage: index,
			lightboxIsOpen: true,
		});
	}
  closeLightbox = () => {
		this.setState({
			currentImage: 0,
			lightboxIsOpen: false,
		});
	}
  gotoPrevious = () => {
		this.setState({
			currentImage: this.state.currentImage - 1,
		});
	}
  gotoNext = () => {
		this.setState({
			currentImage: this.state.currentImage + 1,
		});
	}
  gotoImage = (index) => {
		this.setState({
			currentImage: index,
		});
	}
  handleClickImage = () => {
		if (this.state.currentImage === this.props.images.length - 1) return;
		this.gotoNext();
	}

  renderGallery = () => {
		const { images } = this.props;
		if (!images) return;

		const gallery = images.map((item, i) => {
			return (
				<a
					href={API_URL + item.path}
					className="gallery-item col-lg-3 col-4 col-ms-6"
					key={i}
					onClick={(e) => this.openLightbox(i, e)}
				>
					<div className={`gallery-image g-img`} style={{
            backgroundImage: `url(${API_URL + item.path})`
          }}></div>
				</a>
			);
		});

		return (
			<div className="gallery row flex-align-start justify-start">
				{gallery}
			</div>
		);
	}
  renderLightbox = () => {
    if(!this.props.images) return;

    const images = this.props.images.map(item => {
      return {src: API_URL + item.path};
    })

    return (
      <Lightbox
        images={images}
        currentImage={this.state.currentImage}
        isOpen={this.state.lightboxIsOpen}
        onClickImage={this.handleClickImage}
        onClickPrev={this.gotoPrevious}
        onClickNext={this.gotoNext}
        onClose={this.closeLightbox}
        onClickThumbnail={this.gotoImage}
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderGallery()}
        {this.renderLightbox()}
      </div>
    )
  }
}

export default Gallery;
